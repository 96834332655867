.\!visible {
  visibility: visible !important
}

.visible {
  visibility: visible
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.relative {
  position: relative
}

.sticky {
  position: sticky
}

.bottom-0 {
  bottom: 0px
}

.left-0 {
  left: 0px
}

.right-0 {
  right: 0px
}

.\!mb-0 {
  margin-bottom: 0px !important
}

.\!mt-0 {
  margin-top: 0px !important
}

.mb-0 {
  margin-bottom: 0px
}

.mb-2 {
  margin-bottom: 0.5rem
}

.mt-4 {
  margin-top: 1rem
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.flex {
  display: flex
}

.table {
  display: table
}

.grid {
  display: grid
}

.hidden {
  display: none
}

.w-full {
  width: 100%
}

.grow {
  flex-grow: 1
}

@keyframes pulse {
  50% {
    opacity: .5
  }
}

.animate-pulse {
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite
}

.resize {
  resize: both
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr))
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr))
}

.grid-cols-\[auto\2c 1fr\] {
  grid-template-columns: auto 1fr
}

.flex-col {
  flex-direction: column
}

.items-center {
  align-items: center
}

.justify-start {
  justify-content: flex-start
}

.justify-end {
  justify-content: flex-end
}

.gap-1 {
  gap: 0.25rem
}

.gap-12 {
  gap: 3rem
}

.gap-2 {
  gap: 0.5rem
}

.gap-4 {
  gap: 1rem
}

.overflow-hidden {
  overflow: hidden
}

.rounded-md {
  border-radius: 0.375rem
}

.rounded-xl {
  border-radius: 0.75rem
}

.rounded-tl-none {
  border-top-left-radius: 0px
}

.border {
  border-width: 1px
}

.border-2 {
  border-width: 2px
}

.border-t {
  border-top-width: 1px
}

.border-danger-shade {
  border-color: var(--ion-color-danger-shade)
}

.border-primary {
  border-color: var(--ion-color-primary)
}

.border-success-shade {
  border-color: var(--ion-color-success-shade)
}

.border-warning-shade {
  border-color: var(--ion-color-warning-shade)
}

.bg-primary-shade {
  background-color: var(--ion-color-primary-shade)
}

.bg-secondary {
  background-color: var(--ion-color-secondary)
}

.bg-white {
  background-color: #ffffff
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem
}

.pb-16 {
  padding-bottom: 4rem
}

.pb-2 {
  padding-bottom: 0.5rem
}

.pb-4 {
  padding-bottom: 1rem
}

.pl-4 {
  padding-left: 1rem
}

.pr-1 {
  padding-right: 0.25rem
}

.pt-1 {
  padding-top: 0.25rem
}

.pt-2 {
  padding-top: 0.5rem
}

.pt-4 {
  padding-top: 1rem
}

.pt-8 {
  padding-top: 2rem
}

.text-center {
  text-align: center
}

.text-right {
  text-align: right
}

.align-top {
  vertical-align: top
}

.\!text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important
}

.\!text-xs {
  font-size: 0.75rem !important;
  line-height: 1rem !important
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem
}

.font-bold {
  font-weight: 700
}

.uppercase {
  text-transform: uppercase
}

.italic {
  font-style: italic
}

.text-danger-contrast {
  color: var(--ion-color-danger-contrast)
}

.text-light {
  color: var(--ion-color-light)
}

.text-secondary {
  color: var(--ion-color-secondary)
}

.text-success-contrast {
  color: var(--ion-color-success-contrast)
}

.text-warning-contrast {
  color: var(--ion-color-warning-contrast)
}

.underline {
  -webkit-text-decoration-line: underline;
          text-decoration-line: underline
}

.line-through {
  -webkit-text-decoration-line: line-through;
          text-decoration-line: line-through
}

.opacity-0 {
  opacity: 0
}

.opacity-100 {
  opacity: 1
}

.outline {
  outline-style: solid
}

.\!filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow) !important
}

.filter {
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow)
}

.transition-opacity {
  transition-property: opacity;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms
}

.duration-700 {
  transition-duration: 700ms
}

.ease-in-out {
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1)
}

@media (min-width: 640px) {
  .sm\:flex-row {
    flex-direction: row
  }

  .sm\:flex-col {
    flex-direction: column
  }

  .sm\:gap-0 {
    gap: 0px
  }

  .sm\:text-left {
    text-align: left
  }
}

@media (min-width: 768px) {
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr))
  }

  .md\:pt-0 {
    padding-top: 0px
  }
}
