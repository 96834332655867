// Ionic Variables and Theming. For more info, please see:
// http://ionicframework.com/docs/theming/

@import "../assets/fonts/inter/inter.css";
@import "../assets/fonts/poppins/poppins.css";

/*
 * Default Theme
 * -------------------------------------------
 */

:root {
    // Application fonts
    --ion-font-family: poppins, sans-serif;
    font-family: poppins, sans-serif;
    font-weight: 400;

    /** primary **/
    --ion-color-primary: #205480;
    --ion-color-primary-rgb: 32, 84, 128;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #1c4a71;
    --ion-color-primary-tint: #36658d;

    /** secondary **/
    --ion-color-secondary: #db8b46;
    --ion-color-secondary-rgb: 219, 139, 70;
    --ion-color-secondary-contrast: #000000;
    --ion-color-secondary-contrast-rgb: 0, 0, 0;
    --ion-color-secondary-shade: #c17a3e;
    --ion-color-secondary-tint: #df9759;

    /** tertiary **/
    --ion-color-tertiary: #7044ff;
    --ion-color-tertiary-rgb: 112, 68, 255;
    --ion-color-tertiary-contrast: #ffffff;
    --ion-color-tertiary-contrast-rgb: 255, 255, 255;
    --ion-color-tertiary-shade: #633ce0;
    --ion-color-tertiary-tint: #7e57ff;
    --ion-color-tertiary-shade-light: #724fe3;

    /** success **/
    --ion-color-success: #a3d81e;
    --ion-color-success-rgb: 163, 216, 30;
    --ion-color-success-contrast: #ffffff;
    --ion-color-success-contrast-rgb: 0, 0, 0;
    --ion-color-success-shade: #8fbe1a;
    --ion-color-success-tint: #bce35d;
    --ion-color-success-shade-light: #a2d71d;

    /** warning **/
    --ion-color-warning: #ffb808;
    --ion-color-warning-rgb: 255, 184, 8;
    --ion-color-warning-contrast: #ffffff;
    --ion-color-warning-contrast-rgb: 0, 0, 0;
    --ion-color-warning-shade: #edab07;
    --ion-color-warning-tint: #ffce57;
    --ion-color-warning-shade-light: #f8b716;

    /** danger **/
    --ion-color-danger: #f04141;
    --ion-color-danger-rgb: 245, 61, 61;
    --ion-color-danger-contrast: #ffffff;
    --ion-color-danger-contrast-rgb: 255, 255, 255;
    --ion-color-danger-shade: #d54242;
    --ion-color-danger-tint: #f47d7d;
    --ion-color-danger-shade-light: #db5e5e;

    /** dark **/
    --ion-color-dark: #2d3137;
    --ion-color-dark-rgb: 45, 49, 55;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #282b30;
    --ion-color-dark-tint: #42464b;

    /** medium **/
    --ion-color-medium: #2d3137;
    --ion-color-medium-rgb: 45, 49, 55;
    --ion-color-medium-contrast: #ffffff;
    --ion-color-medium-contrast-rgb: 255, 255, 255;
    --ion-color-medium-shade: #282b30;
    --ion-color-medium-tint: #42464b;

    /** light **/
    --ion-color-light: #646d7a;
    --ion-color-light-rgb: 148, 160, 176;
    --ion-color-light-contrast: #000000;
    --ion-color-light-contrast-rgb: 0, 0, 0;
    --ion-color-light-shade: #828d9b;
    --ion-color-light-tint: #9faab8;

    --ion-text-color: var(--ion-color-medium);
}

@media (max-width: 480px) {
    .sc-ion-alert-ios {
        --width: 90%;
        --min-width: 90%;
        --max-width: 90%;
    }
}

@media (max-width: 768px) {
    .sc-ion-alert-ios {
        --width: 70%;
        --min-width: 70%;
        --max-width: 70%;
    }
}

@media (min-width: 769px) {
    .sc-ion-alert-ios {
        --width: 50%;
        --min-width: 50%;
        --max-width: 50%;
    }
}

/*
 * Dark Theme
 * -------------------------------------------
 */

.ios body.dark,
.md body.dark {
    --ion-color-primary: #008acc;
    --ion-color-primary-rgb: 0, 138, 204;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #0079b4;
    --ion-color-primary-tint: #1a96d1;

    --ion-color-medium: #cccccc;
    --ion-color-medium-rgb: 204, 204, 204;
    --ion-color-medium-contrast: #000000;
    --ion-color-medium-contrast-rgb: 0, 0, 0;
    --ion-color-medium-shade: #b4b4b4;
    --ion-color-medium-tint: #d1d1d1;

    /** dark **/
    --ion-color-dark: #7f7f80;
    --ion-color-dark-rgb: 127, 127, 128;
    --ion-color-dark-contrast: #ffffff;
    --ion-color-dark-contrast-rgb: 255, 255, 255;
    --ion-color-dark-shade: #707071;
    --ion-color-dark-tint: #8c8c8d;

    --ion-background-color: #202124;
    --ion-background-color-rgb: 32, 33, 36;
    --ion-text-color: #cccccc;
    --ion-text-color-rgb: 204, 204, 204;

    --ion-color-step-50: #292a2c;
    --ion-color-step-100: #313235;
    --ion-color-step-150: #3a3b3d;
    --ion-color-step-200: #424346;
    --ion-color-step-250: #4b4c4e;
    --ion-color-step-300: #545456;
    --ion-color-step-350: #5c5d5f;
    --ion-color-step-400: #656567;
    --ion-color-step-450: #6d6e70;
    --ion-color-step-500: #767778;
    --ion-color-step-550: #7f7f80;
    --ion-color-step-600: #878889;
    --ion-color-step-650: #909091;
    --ion-color-step-700: #98999a;
    --ion-color-step-750: #a1a1a2;
    --ion-color-step-800: #aaaaaa;
    --ion-color-step-850: #b2b2b3;
    --ion-color-step-900: #bbbbbb;
    --ion-color-step-950: #c3c3c4;
    --ion-border-color: var(--ion-color-step-100);

    .datatable-row-even {
        background: var(--ion-color-step-200);
    }

    ion-content {
        --background: var(--ion-background-color);
    }

    ion-card,
    ion-card-header,
    ion-card-content {
        --background: var(--ion-color-step-100) !important;
    }

    ion-list,
    ion-grid,
    ion-col,
    ion-row,
    ion-item {
        --background: transparent !important;
        background: transparent !important;
    }

    h1,
    h2,
    h3,
    ion-label h1,
    ion-label h2,
    ion-label h3,
    ion-title,
    ion-card-title,
    ion-list-header {
        color: #e5e5e5 !important;
    }

    ion-tab-button {
        --color: #e5e5e5;
    }
}

/*
 * Color classes
 */

.color-primary {
    color: var(--ion-color-primary);
}

.color-secondary {
    color: var(--ion-color-secondary);
}

.color-tertiary {
    color: var(--ion-color-tertiary);
}

.color-success {
    color: var(--ion-color-success);
}

.color-warning {
    color: var(--ion-color-warning);
}

.color-danger {
    color: var(--ion-color-danger) !important;
}

.color-dark {
    color: var(--ion-color-dark);
}

.color-medium {
    color: var(--ion-color-medium);
}

.color-light {
    color: var(--ion-color-light);
}

.hidden {
    display: none !important;
}

/**
 * Paddings
 */

.padding-top-none {
    padding-top: 0 !important;
}

.padding-right-none {
    padding-right: 0 !important;
}

.padding-bottom-none {
    padding-bottom: 0 !important;
}

.padding-left-none {
    padding-left: 0 !important;
}

.padding-top-bottom-none {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
}

.padding-left-right-none {
    padding-left: 0 !important;
    padding-right: 0 !important;
}

/**
 * Margins
 */

.margin-none {
    margin: 0 0 0 0 !important;
}

.margin-top-l {
    margin-top: 32px;
}

.margin-top-none {
    margin-top: 0 !important;
}

.margin-right-none {
    margin-right: 0 !important;
}

.margin-bottom-none {
    margin-bottom: 0 !important;
}

.margin-left-none {
    margin-left: 0 !important;
}

.margin-top-bottom-none {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
}

.margin-left-right-none {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

/**
 * Display
 */

.display-block {
    display: block;
}

.inline-inline-block {
    display: inline-block;
}

.equal-height,
.full-height {
    height: 100%;
}

/**
 * Text
 */

.text-clip {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

.text-strong {
    font-weight: 500 !important;
}

.font-bold {
    font-weight: 600 !important;
}

/**
 * Core Elements
 */

hr {
    border-top: 1px solid var(--ion-color-light-shade) !important;
    margin: 12px 0 12px 0;
}

h1 {
    color: var(--ion-color-dark);
    font-weight: 600 !important;
}

h2 {
    color: var(--ion-color-dark);
    font-weight: 600 !important;
}

h3 {
    color: var(--ion-color-medium);
    font-size: 16px !important;
    font-weight: 500 !important;
}

p {
    color: var(--ion-color-text);
}

.ios.sc-ion-label-ios-s > p {
    color: var(--ion-color-text);
}

.ios ion-modal ion-header ion-toolbar {
    padding-top: constant(safe-area-inset-top) !important;
    padding-top: env(safe-area-inset-top) !important;
}

ion-title {
    --color: var(--ion-color-dark);
}

/*
body:not(.dark),
body:not(.dark) {
    ion-searchbar {
        padding-top: 15px !important;
        margin-bottom: 15px !important;
        --box-shadow: rgba(0, 0, 0, 0.1) 0px 2px 8px !important;
        --background: #f9fafc !important;
        --color: var(--ion-color-primary-tint) !important;
        --icon-color: var(--ion-color-medium) !important;
        --clear-button-color: var(--ion-color-primary) !important;
        --placeholder-color: var(--ion-color-medium) !important;
        --placeholder-opacity: 1 !important;

        &.searchbar-has-value {
            --icon-color: var(--ion-color-primary) !important;
        }
    }
}
*/

ion-content {
    --padding-bottom: 80px !important;
}

ion-footer {
    ion-title {
        font-weight: 400;
    }
}

/*
 * Segment buttons
 */

body:not(.dark),
body:not(.dark) {
    ion-segment {
        --background: var(--ion-color-primary);
    }

    ion-segment-button {
        --color: var(--ion-color-primary-contrast);

        &.segment-button-disabled {
            color: var(--ion-color-medium);
            opacity: 0.3;
        }
    }
}

/*
 * Toolbar
 */

ion-header {
    ion-toolbar {
        //--padding-bottom: 16px !important;
    }
}

/**
 * Icons
 */

fa-icon {
    color: var(--ion-color-dark-tint);
}

/**
 * Lists
 */

ion-item-divider {
    font-size: 17px !important;
    font-weight: 500 !important;
    --padding-top: 4px;
    --padding-bottom: 4px;
    margin: 24px 0 16px 0;

    body:not(.dark) {
        --background: #f9fafc;
        --color: var(--ion-color-primary-tint);
    }
}

ion-list-header {
    font-family: poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 28px !important;
    margin: 16px 0 16px 0;
    color: var(--ion-color-dark);
}

ion-item.ios .align-top {
    align-self: flex-start;
    margin-top: 10px;
}

.item-interactive-disabled ion-label,
.select-disabled,
.datetime-disabled,
input[disabled],
textarea[disabled] {
    --color: #ffffff !important;
    --opacity: 1 !important;
    color: #ffffff !important;
    opacity: 1 !important;
    -webkit-text-fill-color: #ffffff !important;
}

body:not(.dark) {
    .item-interactive-disabled ion-label,
    .select-disabled,
    .datetime-disabled,
    input[disabled],
    textarea[disabled] {
        --color: #000000 !important;
        --opacity: 1 !important;
        color: #000000 !important;
        opacity: 1 !important;
        -webkit-text-fill-color: #000000 !important;
    }
}

/**
* Forms
*/

.ios,
.md {
    ion-input,
    ion-select,
    ion-datetime,
    ion-textarea {
        font-weight: 600;

        body:not(.dark) {
            color: var(--ion-color-dark);
            --color: var(--ion-color-dark);
            --placeholder-color: var(--ion-color-medium) !important;
            --placeholder-opacity: 1 !important;
        }
    }

    ion-textarea {
        font-style: italic;
    }

    ion-label.label-stacked {
        h3 {
            margin-top: 0;
            padding-top: 12px;
        }
    }

    .input-padding-top {
        padding-top: 12px;
    }
}

.required-asterix {
    color: var(--ion-color-danger) !important;
    font-weight: 700;
}

/**
 * Cards
 */

ion-card-title {
    font-family: poppins, sans-serif;
    font-style: normal;
    font-weight: 800;
    color: var(--ion-color-dark);

    ion-button {
        height: 100%;
    }
}

ion-card-subtitle {
    font-family: poppins, sans-serif;
    font-style: normal;
    font-weight: 700;
    color: var(--ion-color-light);
}

ion-card-content {
    font-size: 17px;
    color: var(--ion-color-medium) !important;
}

ion-card.has-footer {
    display: flex;
    flex-direction: column;

    ion-card-content {
        flex: 1;
    }

    ion-card-content.ion-no-padding {
        padding-bottom: 16px;
    }

    ion-footer {
        ion-toolbar {
            --padding-top: 16px !important;
            --padding-bottom: 16px !important;
            --padding-start: 16px !important;
        }
    }
}

.card-background-icon {
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 80px;
}

.card-primary {
    ion-card-title,
    ion-card-subtitle,
    ion-card-content,
    ion-item,
    ion-list,
    ion-label,
    ion-note,
    .ion-item-native {
        background: transparent !important;
        color: var(--ion-color-primary-contrast) !important;
        --background: transparent;
    }

    ion-item-divider {
        background: var(--ion-color-primary-shade) !important;
        color: var(--ion-color-primary-contrast) !important;
    }

    background: var(--ion-color-primary);
    background: linear-gradient(
        160deg,
        var(--ion-color-primary-tint) 0%,
        var(--ion-color-primary) 100%
    );

    .card-background-icon {
        opacity: 0.3;
        color: var(--ion-color-primary-shade-light);
    }
}

.card-success {
    ion-card-title,
    ion-card-subtitle,
    ion-card-content,
    ion-item,
    ion-list,
    ion-label,
    ion-note,
    .ion-item-native {
        background: transparent !important;
        color: var(--ion-color-success-contrast) !important;
        --background: transparent;
    }

    ion-item-divider {
        background: var(--ion-color-success-shade) !important;
        color: var(--ion-color-success-contrast) !important;
    }

    background: var(--ion-color-success);
    background: linear-gradient(
        160deg,
        var(--ion-color-success-tint) 0%,
        var(--ion-color-success) 100%
    );

    .card-background-icon {
        opacity: 1;
        color: var(--ion-color-success-shade-light);
    }
}

.card-warning {
    ion-card-title,
    ion-card-subtitle,
    ion-card-content,
    ion-item,
    ion-list,
    ion-label,
    ion-note,
    .ion-item-native {
        background: transparent !important;
        color: var(--ion-color-warning-contrast) !important;
        --background: transparent;
    }

    ion-item-divider {
        background: var(--ion-color-warning-shade) !important;
        color: var(--ion-color-warning-contrast) !important;
    }

    background: var(--ion-color-warning);
    background: linear-gradient(
        160deg,
        var(--ion-color-warning-tint) 0%,
        var(--ion-color-warning) 100%
    );

    .card-background-icon {
        opacity: 1;
        color: var(--ion-color-warning-shade-light);
    }
}

.card-danger {
    ion-card-title,
    ion-card-subtitle,
    ion-card-content,
    ion-item,
    ion-list,
    ion-label,
    ion-note,
    .ion-item-native {
        background: transparent !important;
        color: var(--ion-color-danger-contrast) !important;
        --background: transparent;
    }

    ion-item-divider {
        background: var(--ion-color-danger-shade) !important;
        color: var(--ion-color-danger-contrast) !important;
    }

    background: var(--ion-color-danger);
    background: linear-gradient(
        160deg,
        var(--ion-color-danger-tint) 0%,
        var(--ion-color-danger) 100%
    );

    .card-background-icon {
        opacity: 1;
        color: var(--ion-color-danger-shade-light);
    }
}

.card-background-icon {
    opacity: 0.05;
}

.card-image {
    height: 25vh;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    overflow: hidden;
}

.image-contain {
    background-size: contain !important;
}

/**
 * Grids
 */

ion-list {
    ion-col {
        padding-left: 0;
    }
}

/*
@media (min-width: 700px) {
     // Reduce the min width to support two columns in galaxy tabs.

    ion-col {
        flex: 0 0 calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
        max-width: calc(calc(6 / var(--ion-grid-columns, 12)) * 100%) !important;
    }
}
*/

/**
 * Chips
 */

ion-chip {
    margin-left: 0;
}

.chip-full-width ion-chip,
ion-chip.chip-full-width {
    display: inline-block;
    width: 100%;
}

ion-chip.ion-color-primary {
    color: var(--ion-color-primary-contrast);
    background: var(--ion-color-primary);
}

ion-chip.ion-color-primary.chip-outline {
    color: var(--ion-color-primary);
    background: #ffffff !important;
    border-color: var(--ion-color-primary);
    border-width: 1px;
}

ion-chip.ion-color-secondary {
    color: var(--ion-color-secondary-contrast);
    background: var(--ion-color-secondary-tint);
}

ion-chip.ion-color-secondary.chip-outline {
    color: var(--ion-color-secondary);
    background: #ffffff !important;
    border-color: var(--ion-color-secondary);
    border-width: 1px;
}

ion-chip.ion-color-success {
    color: var(--ion-color-success-contrast);
    background: var(--ion-color-success-tint);
}

ion-chip.ion-color-success.chip-outline {
    color: var(--ion-color-success);
    background: #ffffff !important;
    border-color: var(--ion-color-success);
    border-width: 1px;
}

ion-chip.ion-color-warning {
    color: var(--ion-color-warning-contrast);
    background: var(--ion-color-warning-tint);
}

ion-chip.ion-color-warning.chip-outline {
    color: var(--ion-color-warning);
    background: #ffffff !important;
    border-color: var(--ion-color-warning);
    border-width: 1px;
}

ion-chip.ion-color-danger {
    color: var(--ion-color-danger-contrast);
    background: var(--ion-color-danger-tint);
}

ion-chip.ion-color-danger.chip-outline {
    color: var(--ion-color-danger);
    background: #ffffff !important;
    border-color: var(--ion-color-danger);
    border-width: 1px;
}

/**
 * Alerts
 */

.alert-title {
    color: var(--ion-color-dark) !important;
}

/**
 * Modals
 */

.modal-fullscreen {
    .modal-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        width: 100%;
        height: 100%;
    }
}

ion-modal.modal-fullscreen {
    --border-radius: 0 !important;
}

/**
 * Action sheets
 */

.action-sheet-group-cancel {
    button {
        color: var(--ion-color-medium) !important;
    }
}

/**
 * Charts
 */

.chart {
    display: flex;
    overflow: hidden;

    .pie-label {
        font-size: 14px;
    }

    .chart-legend .legend-label-text {
        font-size: 14px;
    }

    .legend-labels {
        background: none !important;
    }
}

.chart-tooltip {
    * {
        font-family: poppins, sans-serif !important;
        font-weight: 400 !important;
    }

    .chart-tooltip-header {
        color: var(--ion-color-dark);
        font-size: 14px !important;
        font-weight: 500 !important;
    }
}

:root {
    --chart-tooltip-background: #f9fafc;
}

/**
 * Tables
 */

h2 {
    color: var(--ion-color-dark);
    font-weight: 600 !important;
}

h3 {
    color: var(--ion-color-medium);
    font-size: 16px !important;
    font-weight: 500 !important;
}

.ngx-datatable {
    width: 100%;
}

.datatable-body-cell {
    padding-left: 4px;
}

.datatable-header-cell-label {
    color: var(--ion-color-medium);
    font-weight: 600 !important;
    font-size: 17px !important;
}

.datatable-row-even {
    background: #f9fafc;
}

.datatable-body-row.active {
    background: var(--ion-color-primary-tint);

    .datatable-body-cell-label {
        color: var(--ion-color-primary-contrast);
    }
}

showdown {
    h1 {
        font-size: 22px;
        margin-top: 36px;
        margin-bottom: 12px;
    }

    h2 {
        font-size: 18px;
        margin-bottom: 4px;
        color: var(--ion-color-secondary);
    }

    ul {
        margin-bottom: 20px;
    }

    ul {
        padding-left: 16px;
        list-style-type: circle;
    }

    strong {
        font-size: 14px;
    }
}
